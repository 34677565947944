<template>
  <div>
    <CCard class="col-lg-6 col-sm-12">
      <CCardHeader>
        Node List
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="12">
            <div class="position-relative">
              <CDataTable
                hover
                small
                fixed
                table-filter
                sorter
                items-per-page-select
                :items-per-page="15"
                :fields="nodeListFields"
                :items="nodeListItem"
                :noItemsView="{ noItems: 'No Item~' }"
                pagination
              >
                <template #name="{item}">
                  <td class="py-2">
                    <CLink @click="showNodeInfoModal(item)">{{ item.name }}
                    </CLink>
                  </td>
                </template>
                <template #online="{item}">
                  <td class="py-2">
                    <CBadge color="primary">Online<CIcon name="cil-smile-plus" />:{{ item.online_user }}</CBadge>
                    <CBadge :color='nodeStatus[item.online]["color"]'>{{ nodeStatus[item.online]["status"] }}</CBadge>
                  </td>
                </template>
              </CDataTable>
              <CElementCover
                :boundaries="[{ sides: ['top', 'left'], query: '.media-body' }]"
                :opacity="1"
                v-if="nodeLoading"
              >
                <h1 class="d-inline">Loading... </h1>
                <CSpinner size="2xl" color="success" />
              </CElementCover>
            </div>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <CToaster :autohide="3000">
      <template v-for="toast in fixedToasts">
        <CToast
          :key="'toast' + toast"
          :show="true"
          :color="alertLevel"
          header="Alert"
        >
          {{ returnMsg }}
        </CToast>
      </template>
    </CToaster>
    <CModal
      title="Node Infomation"
      color="primary"
      :show.sync="nodeInfoModal"
      size="lg"
    >
      <CCard>
        <CCardHeader>
          <CCardTitle style="color: red">Notice:</CCardTitle>
          <CCardText style="color: red">DO NOT SHARE NODE OR YOUR CONNECTION INFO TO ANY OTHER.</CCardText>
        </CCardHeader>
        <CCardBody>
        </CCardBody>
      </CCard>
      <template #footer>
        <CButton @click="nodeInfoModal=false" color="primary">Close</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
export default {
  name: "Info",
  data() {
    const nodeListFields = [
      { key: "online", label: "Node Status" },
      { key: "name", label: "Node Name" },
      { key: "info", label: "Node Remark" }
    ];
    const nodeStatus = {
      0: { "status": "Offline", "color": "danger" },
      1: { "status": "Online", "color": "success" },
      2: { "status": "Unknown", "color": "warning" }
    };
    return {
      nodeLoading: true,
      fixedToasts: 0,
      returnMsg: "",
      alertLevel: "success",
      nodeListFields,
      nodeStatus,
      nodeListItem: [],
      nodeInfoModal: false
    };
  },
  mounted: function() {
    this.onLoadPage();
  },
  methods: {
    onLoadPage: function() {
      this.$axios.get("user/node/list", {})
        .then((response) => {
          if (response.data.code === 200) {
            this.nodeListItem = response.data.nodeList.map(item => {
              let infos = item.status.split("#");
              let source = infos[0];
              let destination = infos[1];
              return {
                ...item,
                source,
                destination
              };
            });
            console.log(this.nodeListItem);
            this.nodeLoading = false;
          }
        });
    },
    showNodeInfoModal: function(nodeItem) {
      this.nodeInfoModal = true;
    }
  }
};
</script>